.clip-wrap {
  text-align: center;
}

.element {
  -webkit-clip-path: polygon(0 100%, 0 0, 100% 0, 80% 100%);
  clip-path: polygon(0 100%, 0 0, 100% 0, 80% 100%);
  -webkit-clip-path: url("#clip-shape"); /* required for Webkit/Blink browsers if you're using only inline SVG clipping paths, but not CSS clip-path */
  clip-path: url("#clip-shape");
}


.clip-block {
  text-align: center;
}

.clip-wrap {
  display: inline-block;
  vertical-align: top;
  padding: 0;
  margin: 0 20px;
}

.clip-svg {
  width: 0;
  height: 0;
}

.clip-css {
  -webkit-clip-path: polygon(41% 0, 100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(41% 0, 100% 0%, 100% 100%, 0% 100%);
}

.clip-svg-inline {
  -webkit-clip-path: url("#clip-polygon");
  clip-path: url("#clip-polygon");
}

.clip-svg-external {
  -webkit-clip-path: url("http://karenmenezes.com/shapes-polygon/clip.svg#clip-polygon-ext");
  clip-path: url("http://karenmenezes.com/shapes-polygon/clip.svg#clip-polygon-ext");
}
