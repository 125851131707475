//
//
// color.scss
//
//


// Text on different colored backgrounds
//
// Adjusts how text should appear in elements where .bg-dark, .bg-image is used

.bg-dark, .text-light, .bg-gray, .text-white{
  h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6,.display-1,.display-2,.display-3,.display-4,{
    color: #fff;
  }
}

.bg-dark,.bg-gray{
  color: $gray-700;
  .title-decorative{
    color: $gray-800;
  }
  .text-muted{
    color: $gray-800 !important;
  }
}

.bg-gray{
  background: $gray-700;
  .text-muted{
    color: $gray-200 !important;
  }
}

.bg-teal{
  background: $teal;
  .text-muted{
    color: $gray-200 !important;
  }
}

.bg-secondary{
  background: $gray-200 !important;
}

.text-light{
  .text-muted{
    color: rgba(255,255,255,.75) !important;
  }
}

.text-light{
  .title-decorative{
    color: #fff;
  }
  a{
    color: $gray-500;
    transition: color .2s ease;
    &:hover{
      color: $white;
    }
  }
}

a.text-white{
  font-weight: $font-weight-bold;
  transition: opacity .2s ease;
  &:hover{
    opacity: .75;
  }
}

.text-body{
  color: $body-color !important;
}

// Forms

.bg-dark{
  .form-group{
    label{
      color: #fff;
    }
    small{
      color: $gray-600;
    }
  }
}

.text-white{
  .form-group{
    small{
      color: #fff;
    }
  }
}

// Gradient Background

.bg-gradient{
  //background: linear-gradient(135deg, $teal 0%,$gray-400 100%);
  background: #39d7c3; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #39d7c3 0%, #86d6cb 64%, #86d6cb 64%, #39d7c3 64%, #39d7c3 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #39d7c3 0%,#86d6cb 64%,#86d6cb 64%,#39d7c3 64%,#39d7c3 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(123deg, #39d7c3 0%,#86d6cb 800px,#39d7c3 800px,#39d7c3 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#39d7c3', endColorstr='#39d7c3',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  }

.bg-gray-gradient{
  background: #f1f2f2; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #f1f2f2 0%, #f1f2f2 64%, #ffffff 64%, #ffffff 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #f1f2f2 0%,#f1f2f2 64%,#ffffff 64%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(123deg, #f1f2f2 0%,#f1f2f2 728px,#ffffff 728px,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f1f2f2', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.bg-yearbook-features{
  background: #f1f2f2; /* Old browsers */
  background: -moz-linear-gradient(-45deg, #f1f2f2 0%, #f1f2f2 64%, #ffffff 64%, #ffffff 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #f1f2f2 0%,#f1f2f2 64%,#ffffff 64%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(123deg, #bcbec0 0%,#bcbec0 413px,#cae6e2 413px,#cae6e2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f1f2f2', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.bg-customers-say{
  background: #ffdb6a; /* Old browsers */
  background: linear-gradient(123deg, #ffdb6a 0%,#ffdb6a 506px,#ffe79a 506px,#ffe79a 906px,#ffdb6a 906px, #ffdb6a 100% ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

}

.bg-picture-day{
  background: #495057;
}

.bg-picture-day-order-section{
  background: #ffdb6a; /* Old browsers */
  background: linear-gradient(123deg, #ffffff 0px,#ffffff 906px,#f1f2f2 906px, #f1f2f2 100% ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

}

.bg-picture-day-featured-products{
  background: #ffdb6a; /* Old browsers */
  background: linear-gradient(123deg, #289688 0px,#289688 726px,#c8cacc 726px, #c8cacc 100% ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

}

.bg-picture-day-help{
  background: #868e96; /* Old browsers */
  background: linear-gradient(123deg, #868e96 0px,#868e96 551px,#55baaf 551px, #55baaf 100% ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

}

.bg-family{
  background: #5f5f5f; /* Old browsers */
  background: linear-gradient(123deg, #5f5f5f 0px,#5f5f5f 660px,#579087 660px, #579087 100% ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

}

.bg-services{
  background: #e6e6e6; /* Old browsers */
  background: linear-gradient(123deg, #e6e6e6 0px,#e6e6e6 290px, #ffffff 290px, #ffffff 1100px, #e6e6e6 1100px, #e6e6e6 100% ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
// Text color utility classes
//
// Create text utility classes for all items in the Bootstrap $colors list

@each $current-color in $colors{
  $key: nth($current-color,1);
  $value: nth($current-color,2);
  .text-#{$key}{ color: $value !important; }
}
