//
//
// buttons.scss
//
//


// Bootstrap overrides
//
// Small adjustments over base Bootstrap button styling

.btn-link{
  font-weight: $font-weight-bold;
}

.btn-warning{
  color: $white;
}

.btn-outline-primary, .btn-outline-secondary{
  &:not(:hover):not([aria-expanded="true"]):not([aria-pressed="true"]){
    border-color: $gray-400;
  }
}

.btn-sm{
  font-size: 0.75rem;
}

.btn-lg{
  font-size: 1rem;
}

.btn.disabled,.btn:disabled{
  opacity: .35;
}

.btn-light{
  color: theme-color("primary");
  &:hover{
    color: theme-color("primary");
  }
}

[class*='btn-outline'].active, [class*='btn-outline']:active{
  color: $white !important;
}

button.btn-outline-primary[aria-expanded="true"],button.btn-outline-primary:active{
  color: $white !important;
}

.bg-gradient{
  .btn-light{
    background: rgba(255,255,255,.3);
    border-color: rgba(255,255,255,0);
    color: $white;
    &:hover{
      background: $white;
      color: $blue;
    }
  }
}
